.exp-tile {
    text-align: center;
    border-radius: 5vh;
    min-height: 5vh;
    margin: 0 auto;
}

.exp-h1 {
    font-size: 5vh;
    font-weight: bold;
    margin: 0;
    margin-bottom: 3vh;
}

.exp-h3 {
    font-size: 2.5vh;
    text-align: center;
    font-weight: bold;
}

.more-button {
    color: seagreen !important;
}

.more-button:disabled {
    color: grey !important;
}

.timeline {
    position: relative;
    max-width: 800px;
}

.timeline-event {
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
}

.timeline-dot {
    width: 3em;
    height: 3em;
    background-color: seagreen;
    border-radius: 50%;
    position: absolute;
    left: -1.5em;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
}

.timeline-event {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.timeline-content {
    color: #fff;
}

.timeline-content p {
    margin: 1vh 0;
}

.timeline-date {
    font-size: 1rem;
    margin-bottom: 3px;
    min-width: 10vw;
    max-width: 10vw;
}

.timeline:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: seagreen;
}