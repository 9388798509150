.custom-dialog {
    background: linear-gradient(90deg, #000, #050a0a, #000);
    color: #fff !important;
    border-radius: 2vh !important;
    box-shadow: 0;
    min-width: 70vw;
    margin: 0 !important;
}

.dialog-title {
    font-weight: bold;
    text-align: center;
    margin: 2vh 0vw 0vh 0vw !important;
}

.dialog-company{
    font-size: 1rem;
    font-weight: 500;
    text-align: center;
}

.css-ypiqx9-MuiDialogContent-root{
    padding: 1vh 2vw !important;
}

.dialog-content {
    padding: 1vh 2vw;
    text-align: center;
}

.dialog-content p{
    font-size: 1.1em;
    text-align: justify;
    white-space: pre-line;
}

.dialog-actions {
    justify-content: center !important;
    padding-bottom: 3vh !important;
}

.dialog-button {
    background-color: seagreen !important;
    color: #fff !important;
    padding: 1vh 1vw !important;
    border-radius: 2vh !important;
    transition: background-color 0.3s ease !important;
    border: none !important;
    cursor: pointer !important;
    min-width: 2vw !important;
    min-height: 3vh !important;
}

.dialog-button:hover {
    background-color: rgb(70, 211, 131) !important;
}
