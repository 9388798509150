.notfound-h1{
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.notfound-p{
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
}