.skills-tile {
  text-align: center;
  border-radius: 5vh;
  min-height: 5vh;
  margin: 0 auto;
}

.skills-p {
  text-align: justify !important;
  font-size: 1.55rem;
  padding: 1rem;
}

.skills-h1 {
  font-size: 3rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 3vh;
}

.skills-h4{
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 3vh;
}

.skills-span-stack-items {
  font-size: 2vh;
  margin-bottom: 5vh;
}

.img-tech{
  margin: 0 auto;
  margin-right: 1vw;
}

.icon{
  margin: 0 auto;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 1vh 1vh 1vh 1vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.right-slide{
  animation: slideInFromRight 1s cubic-bezier(1, -0.2, 0, 1.18);
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
