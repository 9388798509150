.coming-h1 {
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.coming-p {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.coming-tile {
    text-align: center;
    border-radius: 5vh;
    min-height: 5vh;
    margin: 0 auto;
}

.main-styles1 {
    position: relative;
    text-align: center;
    padding: 5vh 3vh 5vh 3vh;
    animation: slideInFromLeft 1s cubic-bezier(1, -0.2, 0, 1.18);
    color: white;
}