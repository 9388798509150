.main-styles {
    text-align: center;
    animation: slideInFromLeft 1s cubic-bezier(1, -0.2, 0, 1.18);
    color: white;
    transition: box-shadow 0.3s ease;
  }
  
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  .root {
    text-align: center;
    margin: 0 auto;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  
  .root>* {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  
  .root> :first-child {
    margin-top: 0 !important;
  }
  
  @media only screen and (max-width: 599px) {
    .root {
      width: 20rem;
    }
  }
  
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    .root {
      width: 22rem;
    }
  }
  
  @media only screen and (min-width: 960px) and (max-width: 1279px) {
    .root {
      width: 28rem;
    }
  }
  
  @media only screen and (min-width: 1280px) and (max-width: 1919px) {
    .root {
      width: 32rem;
    }
  }
  
  @media only screen and (min-width: 1920px) {
    .root {
      width: 36rem;
    }
  }
  
  .avatar {
    margin: 0 auto;
    margin-bottom: 1.25rem;
  }
  
  @media only screen and (max-width: 599px) {
    .avatar {
      width: 5em !important;
      height: 5em !important;
    }
  }
  
  @media only screen and (min-width: 600px) and (max-width: 959px) {
    .avatar {
      width: 6em !important;
      height: 6em !important;
    }
  }
  
  @media only screen and (min-width: 960px) and (max-width: 1279px) {
    .avatar {
      width: 6em !important;
      height: 6em !important;
    }
  }
  
  @media only screen and (min-width: 1280px) and (max-width: 1919px) {
    .avatar {
      width: 7em !important;
      height: 7em !important;
    }
  }
  
  @media only screen and (min-width: 1920px) {
    .avatar {
      width: 8em !important;
      height: 8em !important;
    }
  }
  
  .text-name {
    color: white;
    letter-spacing: -0.075rem !important;
    width: 100% !important;
    line-height: 1.25 !important;
    font-weight: bold !important;
    font-size: 3rem !important;
    margin: 1.25rem 0;
  
  }
  
  .text-position{
    color: white;
    font-family: "Source Sans Pro", sans-serif !important;
    letter-spacing: -0.025rem !important;
    width: 100% !important;
    line-height: 2 !important;
    font-weight: 600 !important;
    margin: 0.5rem 0;
    font-size: 1.5rem !important;
  }

  .text-desc {
    text-align: justify;
    font-weight: 500;
    padding: 1rem 6rem;
  }
  
  
  .root hr {
    background-color: rgba(0, 0, 0, 0.5);
    align-self: stretch;
    margin: 0 !important;
    border-color: transparent;
  }

  .cv-button{
    background-color: seagreen !important;
    color: white !important;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.5rem 1rem !important;
    width: 5rem !important;
    height: 5rem !important;
    border-radius: 4rem !important;
    text-decoration: none;
    transition: background-color 0.3s ease;
    margin: 0 1rem !important;
  }
