.header-projects {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 3rem;
    font-weight: bold;
    color: white;

}

.project-main {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: white;
}

.project-name {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2vh;
}

.project-desc {
    text-align: justify;
    font-size: 0.8em;
    font-weight: 700;
}

.project-link {
    font-size: 1rem;
}

.project-span {
    font-size: 1rem;
    font-style: italic;
    text-align: left !important;
    margin-top: 1vh;
}

.project-img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.right-slide {
    animation: slideInFromRight 1s cubic-bezier(1, -0.2, 0, 1.18);
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}

.image-style {
    display: inline-block;
    margin-right: 1vw;
}

.link-to {
    color: #007bff;
    cursor: pointer;
}

.link-to:hover {
    color: #0056b3;
}