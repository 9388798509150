.nav {
    background: linear-gradient(90deg, #000, #050b0b, #000);
    box-shadow: inset 0 -20px 40px -25px #000;
}

.links {
    justify-content: space-between;
    cursor: pointer;
}

.link{
    font-size: 1.2rem;
}

.link:hover {
    font-size: 1.3rem;
    transition: font 0.5s ease-in-out;
}

.active{
    font-size: 1.5rem;
    color:seagreen;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    min-width: 0 !important;
    padding: 0 !important;
}

.reactCountriesFlags{
    width: 1.75rem !important;
    height: 1.25rem !important;
}