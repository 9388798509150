.contact-h1 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 0.8rem;
}

.contact-p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.main-styles-contact {
    position: relative;
    text-align: center;
    padding: 5vh 3vh 5vh 3vh;
    min-width: 5vh;
    min-height: 10vh;
    animation: slideIn 1s cubic-bezier(1, -0.2, 0, 1.18);
    color: white;
    transition: box-shadow 0.3s ease;
}

@keyframes slideIn {
    0% {
      transform: translateY(-100%);
    }
  
    100% {
      transform: translateY(0);
    }
  }

.buttons-div {
    display: flex;
    justify-content:center;
    padding: 1vh;
    gap: 0.1em;
}

.submit-button{
    background-color: seagreen !important;
    color: white !important;
    font-size: 1rem !important;
    font-weight: 600 !important;
    padding: 0.5rem 1rem !important;
    border-radius: 2rem !important;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.3s ease !important;
    margin: 0 1rem !important;
    border: none !important;
}

.input-text {
    margin-top: 0.7rem;
    margin-bottom: 0.5rem;
    background-color: #fff;
    color: #fff;
    padding: 0.5rem;
    width: 100%;
    font-size: 1rem;
    box-sizing: border-box;
    border-radius: 8px;
}

.error-message, .succed-message{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 0.6rem;
}

.error-message {
    color: rgb(111, 11, 11);
}

.succed-message {
    color: rgb(11, 111, 43);
}

.textarea {
    height: 6rem;
}

.mail-icon{
    margin: 0 auto;
}

.form-container input, select, textarea{
    border : 2px solid #fff !important;
    border-radius: 4vh !important;
    background-color: transparent !important;
    color: #fff;
}

.form-container option{
    background-color: #000;
    color: #fff;
}
