.policy-div{
    margin: 0 5%;
    color: white;
    text-align: left;
    margin-bottom: 6rem;
}

.policy-div h1{
    text-align: center;
    font-size: 5vh;
    font-weight: bold;
}

.policy-div h2{
    font-size: 4vh;
    font-weight: bold;
}

.policy-div p{
    font-size: 1rem;
    text-align: justify;
    margin: 2vh 0 2vh 2vw;
    white-space: pre-line;
}