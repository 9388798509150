.sidebar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(90deg, #000, #060f0d, #000);
    color: #fff;
    transition: all 0.3s;
    font-weight: bold;
}

.sidebar-link{
    margin: 0 auto;
}